<template>
  <div class="no-pad-wrap">
    <tabs>
      <template v-slot:instruments>
        <InstrumentDropdown class="normal" />
      </template>
      <template v-slot:before>
        <input
          v-model="gridFilter"
          placeholder="Filter grid"
          class="grid-filter"
          type="text"
        >
      </template>
      <tab :index="0">
        <GridSetup
          :processed-data="trades"
          :search-filter="gridFilter"
          :pagination="true"
        />
      </tab>
      <tab :index="1">
        <GridSetup
          :processed-data="trades"
          :search-filter="gridFilter"
          :pagination="true"
        />
      </tab>
      <tab :index="2">
        <GridSetup
          :processed-data="trades"
          :search-filter="gridFilter"
          :pagination="true"
        />
      </tab>
    </tabs>
  </div>
</template>

<script>
import InstrumentDropdown from '@/views/components/InstrumentDropdown.vue'
import Ripple from 'vue-ripple-directive'
import useTrades from '@/composables/trades/useTrades'
import useUserSingle from '@/composables/users/useUserSingle'
import { ref } from '@vue/composition-api'
import GridSetup from '../../components/agGrid/GridSetup.vue'
import tab from '../../components/appTab.vue'
import tabs from '../../components/appTabs.vue'

export default {
  name: 'Analysis',
  components: {
    GridSetup,
    tab,
    tabs,
    InstrumentDropdown,
  },
  directives: { Ripple },
  setup() {
    const { trades, getInitialTrades } = useTrades() // add pagi
    const { user } = useUserSingle()

    const gridFilter = ref('')

    getInitialTrades()

    return {
      trades,
      user,
      gridFilter,
    }
  },
  computed: {
    chart() {
      return this.$isDark.value
        ? {
          background: 'rgba(71, 183,132,.5)',
          text: 'white',
          grid: 'white',
          dataset1: 'blue',
          dataset2: 'green',
          dataset3: 'orange',
        }
        : {
          background: 'red',
          text: 'white',
          grid: 'white',
          dataset1: 'blue',
          dataset2: 'green',
          dataset3: 'orange',
        }
    },
  },
  methods: {
    exportCsv() {
      const event = new CustomEvent('exportCsv')
      window.dispatchEvent(event)
    },
  },
}
</script>

<style lang="scss" >
@use "../../components/agGrid/components/optionsBar";

.chart-tab {
  display: inline-block;
  padding: 0rem 1rem 14rem 1rem;
  overflow: auto;
  overscroll-behavior: auto;
  background-color: rgb(243, 243, 243);
  width: 100%;
  height: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

label {
  font-weight: normal !important;
}

.toolbar button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
}

.analysis-hermes-warning {
  background-color: rgb(251, 246, 177);
  font-size: 12px;
  height: 100%;
  padding: 0px 16px 0px 16px;
  border-radius: 0.4rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-nav {
  display: none;
}

.small-metric {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 38px;
  padding-right: 1rem;
}
// dark mode
.dark-layout {
  .analysis-tab {
    background-color: #1f1f1f;
    .chart-tab {
      background-color: #1f1f1f;
    }
  }
  .analysis-grid {
    background-color: hsl(0, 0%, 12%);
    border-left: 5px solid hsl(0, 0%, 12%);
    border-right: 5px solid hsl(0, 0%, 12%);
  }
  .analysis-hermes-warning {
    background-color: rgb(129, 120, 0);
  }
}
</style>
